/**
 * Maps
 * @dependecies: https://hpneo.github.io/gmaps/
 */

;(function (window, document) {
    'use strict';

    var $private = {};

    var Maps = function (output) {
        this.container = output
    }

    Maps.prototype = {
        constructor : Maps,

        render : function (maps, mark) {
            console.log(this.container);

            var map = new GMaps({
                div : this.container,
                lat: -12.043333,
                lng: -77.028333,
                disableDefaultUI : false,
                disableDoubleClickZoom : true,
                draggable : true,
                zoom : 17,
                scrollwheel : false
            });

            GMaps.geocode({
                address: maps.getAttribute('data-addr'),
                callback: function(results, status) {
                    if (status === 'OK') {
                        var latlng = results[0].geometry.location;
                        map.setCenter(latlng.lat(), latlng.lng());
                        map.addMarker({
                            lat: latlng.lat(),
                            lng: latlng.lng(),
                            icon: mark
                        });
                    }
                }
            });
        }
    };

    window.Gmaps = Maps
})(window, document);
